<template>
    <div id="privacy">
        <h1>Privacy policies</h1>
        <br>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab debitis eius ea, eos aliquid necessitatibus asperiores ipsum sit reprehenderit! Deleniti tenetur perferendis voluptate doloremque nostrum consectetur non inventore in sed.</p>
        <br>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, nesciunt? Quae et eius, 
            ipsam numquam officia ipsa illum asperiores adipisci dolore doloribus, provident ratione corporis deserunt exercitationem itaque debitis. 
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequuntur illum laboriosam cumque porro, 
            a saepe maxime unde expedita quibusdam modi culpa, voluptas aspernatur, beatae mollitia! Sint culpa nulla nam sunt.</p>
    
    
    </div>

</template>
<style>
#privacy {
    text-align: center;
}
</style>